/* You can add global styles to this file, and also import other style files */

/*
overide progressbar styles for player

*/

.progress {
  background-color: transparent !important;
}

progressbar .progress-bar {
  border-radius: 30px;
  height: 6px !important;
}

.main .chat .content.emoji-view-active {
  height: calc(100vh - 638px) !important;
}

.search-highlight {
  font-weight: bold;
  color: #2196f3;
  text-decoration: underline;
}

.emoji-mart {
  margin-left: 18px !important;
  margin-right: 92px !important;
  width: auto !important;
}

form .form-control.is-invalid {
  border: 2px solid #ffaaaa !important;
}

/* video popup model messagebox*/

.video-view-model {
  height: 100%;
  margin: 0 auto !important;
}
.video-view-model .modal-content {
  height: 100%;
}
.video-view-model .modal-content .video-model-wrapper {
  height: 100%;
}
.video-view-model .modal-content .video-model-wrapper .video-modal {
  height: 100%;
  margin: 0;
}
.video-view-model .modal-content .video-model-wrapper .video-modal plyr {
  height: 100%;
}
.video-view-model .modal-content .video-model-wrapper .video-modal plyr .plyr--video{
  height: 100%;
}
.video-view-model .modal-content .video-model-wrapper video {
  width: 100%;
  max-height: 100%;
}
